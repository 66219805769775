import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromCommon from './common.reducer';

export const selectProfileData =
  createFeatureSelector<fromCommon.AuthUserState>('loggedInUserDetails');

export const selectSsoProfileData =
  createFeatureSelector<fromCommon.SsoAuthUserState>('loggedInSsoUserDetails');

export const selectCommonData =
  createFeatureSelector<fromCommon.CommonState>('commondata');

export const selectCommonDataDtls = createSelector(
  selectCommonData,
  fromCommon.getcommondata
);

export const selectProfilesDtlsData = createSelector(
  selectProfileData,
  fromCommon.getprofiledata
);

export const selectSsoProfilesDtlsData = createSelector(
  selectSsoProfileData,
  fromCommon.getssoprofiledata
);

export const selectAuthloader = createSelector(
  selectSsoProfileData,
  fromCommon.getssoauthloader
);
