import {
  Component,
  OnDestroy,
  OnInit,
  ChangeDetectorRef,
  TemplateRef,
  ElementRef,
  ViewChild,
  AfterViewInit,
  AfterContentChecked,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  languages,
  categories,
  types,
  actionTypes,
  templatebtnactions,
  urlTypes,
} from '../templates.data';
import { CountryData } from 'src/app/appdata/countries.data';
import { MatStepper } from '@angular/material/stepper';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { AlertService } from 'src/app/alert/alert.service';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { EmojiEvent } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import { SharedService } from 'src/app/services/shared.service';
import { TitleCasePipe } from '@angular/common';
import { TemplateService } from 'src/app/services/template.service';
import { selectActionsData } from '../store/template.selectors';
import * as templateactions from 'src/app/customer/templates/store/template.actions';
import * as commonactions from 'src/app/store/common.actions';
import {
  selectCommonDataDtls,
  selectProfilesDtlsData,
  selectSsoProfilesDtlsData,
} from 'src/app/store/common.selectors';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';
import { LoaderService } from 'src/app/services/loader.service';
import { CustomValidators } from 'src/app/services/CustomValidators';
import Delta from 'quill-delta';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-templatesetup',
  templateUrl: './templatesetup.component.html',
  styleUrls: ['./templatesetup.component.scss'],
})
export class TemplatesetupComponent
  implements OnInit, AfterViewInit, AfterContentChecked, OnDestroy
{
  range!: FormGroup;
  templateCreationForm!: FormGroup;
  templateContentForm!: FormGroup;
  addSampleForm!: FormGroup;
  btnactions = templatebtnactions;
  allTypes = types;
  templateTypes = ['None', 'Text', 'Media'];
  file: File;
  countries: Array<any> = [];
  buttonsList: any[] = [];
  selectedDialCode: any;
  showAddBtn = false;
  btnactionTypes: Array<string> = actionTypes;
  destroy$: Subject<boolean> = new Subject<boolean>();
  urlTypes: string[] = urlTypes;
  isSaveToDraft = false;
  editItem: any;
  quill: any;
  content_dtls_value: any;
  charCount = 0;
  header_Var: Array<string> = [];
  headerCharCount = 0;
  bodyCharCount = 0;
  footerCharCount = 0;
  maxCharCount = 60;
  maxBodyCharCount = 1024;
  isLinear = false;
  template_languages = languages;
  allCategories = categories;
  isCollapsed = false;
  chatMessage: string = '';
  channelSub: Subscription;
  userDetails: any;
  templatebody: string = '';
  templateCourser: number;

  account_dtls: any = {
    account_id: null,
    bsp_id: null,
    waba_no: null,
    channel: null,
    createdby: null,
  };

  @ViewChild('stepper') private stepper: MatStepper;
  @ViewChild('headerinp') private headerinp: ElementRef<any>;

  constructor(
    private fb: FormBuilder,
    public shareservice: SharedService,
    private modalService: NgbModal,
    private cd: ChangeDetectorRef,
    private alertMsg: AlertService,
    private readonly store: Store,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private countrydata: CountryData,
    public loader: LoaderService,
    public templateservice: TemplateService,
    public commonservice: CommonService,
    private titlecasePipe: TitleCasePipe,
    private cv: CustomValidators
  ) {}

  get callToActionFormArray(): FormArray {
    return this.templateContentForm.get('ctoaButtons') as FormArray;
  }

  get addsamplectarray(): FormArray {
    return this.addSampleForm.get('ctoaButtons') as FormArray;
  }

  get addsamplequickreplyarray(): FormArray {
    return this.addSampleForm.get('quickreplyBtns') as FormArray;
  }

  get quickreplyFormArray(): FormArray {
    return this.templateContentForm.get('quickreplyBtns') as FormArray;
  }

  get headerVariableArray(): FormArray {
    return this.addSampleForm.get('headerVariables') as FormArray;
  }

  get bodyVariableArray(): FormArray {
    return this.addSampleForm.get('bodyVariables') as FormArray;
  }

  get tcFC() {
    return this.templateCreationForm.controls;
  }
  get tcontentFC() {
    return this.templateContentForm.controls;
  }
  get asFC() {
    return this.addSampleForm.controls;
  }

  handleChannelSubscription() {
    this.channelSub = this.shareservice.selectedBA$.subscribe((value: any) => {
      if (value) {
        this.account_dtls.account_id = value.channel_credentials.account_id;
        this.account_dtls.bsp_id = value.channel_credentials.bsp_id;
        this.account_dtls.waba_no = value.channel_credentials.waba_number;
        this.account_dtls.channel =
          value.channel_credentials.channel_name.toLowerCase();
      }
    });
  }

  checkRouteIsEdit() {
    if (this.router.url.includes('edit')) {
      this.activatedRoute.params.subscribe((params: Params) => {
        const template_id = params['id'];
        this.loader.show();
        this.store.dispatch(
          templateactions.fetchtemplateDtls({
            id: template_id,
          })
        );
      });
    }
  }

  ngOnInit(): void {
    this.handleChannelSubscription();
    this.checkRouteIsEdit();
    if (environment.sso) {
      this.store
        .select(selectSsoProfilesDtlsData)
        .pipe(takeUntil(this.destroy$))
        .subscribe((res) => {
          if (res.loggedInUserDetails) {
            this.userDetails = res.loggedInUserDetails;
            this.account_dtls.createdby =
              res.loggedInUserDetails.profile.first_name +
              ' ' +
              res.loggedInUserDetails.profile.last_name;
          }
        });
    } else {
      this.store
        .select(selectProfilesDtlsData)
        .pipe(takeUntil(this.destroy$))
        .subscribe((res: any) => {
          if (res.loggedInUserDetails) {
            this.userDetails = res.loggedInUserDetails;
            this.account_dtls.createdby =
              res.loggedInUserDetails.profile.first_name +
              ' ' +
              res.loggedInUserDetails.profile.last_name;
          }
        });
    }
    this.countries = this.countrydata.CountryDialCodes;
    this.selectedDialCode = this.countries.find((t) => t.dialCode === '91');

    this.store
      .select(selectActionsData)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: any) => {
        if (
          (res.createtemplatestatus &&
            res.createtemplatestatus.status_code === 201) ||
          (res.updatetemplatestatus &&
            res.updatetemplatestatus.status_code === 200)
        ) {
          this.loader.hide();

          if (this.isSaveToDraft) {
            this.cancelTemplateCreation();
          } else {
            this.stepper.next();
            setTimeout(() => {
              this.cancelTemplateCreation();
            }, 2000);
          }
          // this.isSaveToDraft
          //   ? this.cancelTemplateCreation()
          //   : this.stepper.next();
        } else if (
          res.createtemplatestatus &&
          res.createtemplatestatus.status_code === 500
        ) {
          this.loader.hide();
          this.alertMsg.alertDanger('Error!', res.createtemplatestatus.message);
        } else if (res.templatedtls && res.templatedtls.status_code === 200) {
          this.loader.hide();
          this.openEditTemplate(res.templatedtls.template);
        } else if (res.error) {
          this.loader.hide();
          if (res.error.message) {
            if (res.error.status_code === 400) {
              this.alertMsg.alertWarning(
                'Warning!',
                'Please Check the template content once'
              );
            } else {
              const dispErr = res.error.message;
              this.alertMsg.alertDanger('Error', dispErr);
            }
          }
        }
      });

    this.store
      .select(selectCommonDataDtls)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: any) => {
        if (
          res.uploadRes &&
          res.uploadRes.status_code === 200 &&
          (this.addSampleForm.get('headerMediaType')?.value.toLowerCase() ===
            'image' ||
            this.addSampleForm.get('headerMediaType')?.value.toLowerCase() ===
              'video' ||
            this.addSampleForm.get('headerMediaType')?.value.toLowerCase() ===
              'document')
        ) {
          this.addSampleForm
            .get('uploadMedia')
            .setValue(res.uploadRes.data.url);

          this.store.dispatch(
            templateactions.addsamplePreview({ data: this.addSampleForm.value })
          );
        }
      });

    this.range = new FormGroup({
      start: new FormControl<Date | null>(null, [Validators.required]),
      end: new FormControl<Date | null>(null, [Validators.required]),
    });

    this.templateCreationForm = this.fb.group({
      tempName: [
        null,
        [Validators.required, Validators.pattern(/^[a-z0-9_]+[a-z0-9]+$/)],
      ],
      tempLanguage: [null, Validators.required],
      tempCategory: [null, Validators.required],
      tempType: [null, Validators.required],
    });

    this.templateContentForm = this.fb.group({
      tempHeaderText: [null],
      tempHeaderMedia: [null],
      tempBody: [null, Validators.required],
      enabletempFooter: [false],
      footerText: [null],
      enabletempButtons: [false],
      buttontype: [null],
      ctoaButtons: this.fb.array([]),
      quickreplyBtns: this.fb.array([]),
    });

    this.addSampleForm = this.fb.group({
      headerTemplateType: [null],
      headertext: [null],
      headerVariables: this.fb.array([]),
      headerMediaType: [null],
      uploadMedia: [null],
      bodyText: [null],
      bodyVariables: this.fb.array([]),
      footerText: [null],
      buttontype: [null],
      ctoaButtons: this.fb.array([]),
      quickreplyBtns: this.fb.array([]),
    });
    this.shareservice.datePickerObject.next(null);

    this.templateContentForm
      .get('tempHeaderMedia')
      .valueChanges.subscribe((t) => {
        if (t) {
          this.addSampleForm.get('uploadMedia').setValue(null);
        }
      });

    this.templateCreationForm.get('tempName').valueChanges.subscribe((t) => {
      this.charCount = t ? t.length : 0;
    });
    this.templateContentForm
      .get('tempHeaderText')
      .valueChanges.subscribe((t) => {
        this.header_Var = t ? t.match(/{{\d+}}/g) : null;
        this.headerCharCount = t ? t.length : 0;
        this.addSampleForm.get('uploadMedia').clearValidators();
      });

    this.templateContentForm.get('footerText').valueChanges.subscribe((ftr) => {
      this.footerCharCount = ftr ? ftr.length : 0;
    });

    this.templateContentForm
      .get('enabletempButtons')
      .valueChanges.subscribe((t) => {
        if (!t) {
          this.templateContentForm.get('buttontype').setValue(null);
          this.templateContentForm.get('buttontype').clearValidators();
          this.templateContentForm.get('buttontype').updateValueAndValidity();
          this.callToActionFormArray ? this.callToActionFormArray.clear() : '';
          this.quickreplyFormArray ? this.quickreplyFormArray.clear() : '';

          this.addsamplequickreplyarray.clear();
          this.addsamplectarray.clear();
          if (this.templateContentForm.get('quickreplyBtns')) {
            this.templateContentForm.get('quickreplyBtns').setErrors(null);
            this.templateContentForm.get('quickreplyBtns').clearValidators();
          }
          if (this.templateContentForm.get('ctoaButtons')) {
            this.templateContentForm.get('ctoaButtons').setErrors(null);
            this.templateContentForm.get('ctoaButtons').clearValidators();
          }
          this.templateContentForm.updateValueAndValidity;
        } else {
          this.templateContentForm
            .get('buttontype')
            .setValidators([Validators.required]);
        }
      });

    // Change detection for footer input
    this.templateContentForm
      .get('enabletempFooter')
      .valueChanges.subscribe((val) => {
        if (!val) {
          this.templateContentForm.get('footerText').setValue(null);
          this.templateContentForm.get('footerText').clearValidators();
          this.templateContentForm.get('footerText').updateValueAndValidity();
        } else {
          this.templateContentForm
            .get('footerText')
            .setValidators([Validators.required]);
        }
      });
  }

  ngAfterViewInit() {
    this.templateContentForm.valueChanges.subscribe((value) => {
      if (value) {
        this.store.dispatch(
          templateactions.createtemplatePayload({
            data: value,
          })
        );
      }
    });
    this.addSampleForm.valueChanges.subscribe((value) => {
      if (value) {
        this.store.dispatch(templateactions.addsamplePreview({ data: value }));
      }
    });
    this.cd.detectChanges();
  }

  varReplace(event: any) {
    const pattern = /{{(.*?)}}/g;
    let cleanText;

    if (event.target.value.match(pattern)?.length > 1) {
      const mvar = event.target.value.match(pattern);
      cleanText = event.target.value.replace(mvar[1], '');
    } else {
      cleanText = event.target.value.replace(pattern, '{{1}}');
    }

    this.templateContentForm.controls['tempHeaderText'].setValue(cleanText);
  }

  cancelTemplateCreation() {
    this.gotoListing();
    this.resetForm();
    this.file = null;
    this.editItem = null;
  }

  resetForm() {
    this.templateCreationForm.reset();
    this.templateContentForm.reset();
    this.addSampleForm.reset();
    this.headerVariableArray.clear();
    this.bodyVariableArray.clear();
    this.callToActionFormArray.clear();
    this.quickreplyFormArray.clear();
    this.addsamplectarray.clear();
    this.addsamplequickreplyarray.clear();
    this.stepper.reset();
  }

  gotoListing() {
    this.file = null;
    this.router.navigate([
      'customer',
      this.userDetails.profile.id,
      'templates',
    ]);
  }

  setTempTypeValue(event: any) {
    this.templateContentForm.get('tempHeaderText')?.reset();
    this.file = null;
    this.addSampleForm.get('uploadMedia').setValue(null);
    if (event === 'Text') {
      this.templateContentForm
        .get('tempHeaderText')
        .setValidators([Validators.required, this.cv.noWhitespace()]);
      this.templateContentForm.get('tempHeaderMedia').clearValidators();
      this.templateContentForm.get('tempHeaderText').updateValueAndValidity();
      this.templateContentForm.get('tempHeaderMedia').updateValueAndValidity();
    } else if (event === 'Media') {
      this.templateContentForm
        .get('tempHeaderMedia')
        .setValidators(Validators.required);
      this.templateContentForm.get('tempHeaderText').clearValidators();
      this.templateContentForm.get('tempHeaderText').updateValueAndValidity();
      this.templateContentForm.get('tempHeaderMedia').updateValueAndValidity();
    } else {
      this.templateContentForm.get('tempHeaderText').clearValidators();
      this.templateContentForm.get('tempHeaderMedia').clearValidators();
      this.templateContentForm.get('tempHeaderText').updateValueAndValidity();
      this.templateContentForm.get('tempHeaderMedia').updateValueAndValidity();
    }
    this.shareservice.tempType.next(event);
  }

  openModal(content: TemplateRef<string>, data: string, item?: any) {
    const sizes: any = {
      viewTemplate: 'lg',
      addSample: 'xl',
      deleteTemplate: 'md',
    };
    if (data === 'viewTemplate') {
      this.editItem = item;
      this.shareservice.addsample_data$.next(this.templateContentForm.value);
    }
    this.modalService.open(content, {
      centered: true,
      scrollable: true,
      backdrop: 'static',
      size: sizes[data] || 'md',
    });
  }

  stepTwo() {
    this.store.dispatch(
      templateactions.createtemplatePayload({
        data: this.templateContentForm.value,
      })
    );
  }

  clearAddSampleData() {
    this.headerVariableArray.clear();
    this.bodyVariableArray.clear();
    this.addsamplectarray.clear();
    this.addsamplequickreplyarray.clear();
  }

  stepThree() {
    this.store.dispatch(
      templateactions.addsamplePreview({ data: this.addSampleForm.value })
    );
  }

  addSampleData() {
    const currentContent: any =
      this.templateContentForm.get('tempHeaderText').value;
    if (currentContent) {
      const variableCount = (currentContent.match(/{{\d+}}/g) || []).length;
      if (variableCount > 0 && this.headerVariableArray.controls.length === 0) {
        const data = currentContent.match(/{{\d+}}/g) || [];
        data.forEach((t: string, i: number) => {
          const initialForm: FormGroup = this.fb.group({
            variable: [`variable${t}`],
            variableName: [null, Validators.required],
          });
          this.headerVariableArray.push(initialForm);

          if (
            this.content_dtls_value?.header?.examples &&
            this.content_dtls_value?.header?.examples[i] &&
            this.content_dtls_value?.header.examples[i][`${i + 1}`]
          ) {
            initialForm
              .get('variableName')
              .setValue(this.content_dtls_value.header.examples[i][`${i + 1}`]);
          }
        });
      }
    }
    if (
      this.templateCreationForm.get('tempType')?.value.toLowerCase() === 'media'
    ) {
      this.addSampleForm.controls['uploadMedia'].setValidators([
        Validators.required,
      ]);
      this.addSampleForm.controls['uploadMedia'].updateValueAndValidity();
    }
    const bodyContent: any = this.templateContentForm.get('tempBody').value;
    if (bodyContent) {
      const bodyvariableCount = (bodyContent.match(/{{\d+}}/g) || []).length;
      if (
        bodyvariableCount > 0 &&
        this.bodyVariableArray.controls.length === 0
      ) {
        const data = bodyContent.match(/{{\d+}}/g) || [];
        data.forEach((t: string, i: number) => {
          const initialForm: FormGroup = this.fb.group({
            variable: [`variable${t}`],
            variableName: [null, Validators.required],
          });
          this.bodyVariableArray.push(initialForm);

          if (
            this.content_dtls_value?.body?.examples &&
            this.content_dtls_value?.body?.examples[i] &&
            this.content_dtls_value?.body?.examples[i][`${i + 1}`]
          ) {
            initialForm
              .get('variableName')
              .setValue(this.content_dtls_value?.body.examples[i][`${i + 1}`]);
          }
        });
      }
    }
    if (
      this.templateContentForm.get('ctoaButtons').value &&
      this.addsamplectarray.controls.length === 0
    ) {
      const btn_data = this.templateContentForm.get('ctoaButtons').value;
      btn_data.forEach((t: any) => {
        if (t.urlType === 'Dynamic') {
          const initialForm: FormGroup = this.fb.group({
            type: [t.type],
            text: [t.text],
            country: [t.country],
            phno: [t.phno],
            urlType: [t.urlType],
            url: [t.url],
            urlvariable: [
              t.urlvariable,
              [Validators.required, this.cv.noWhitespace()],
            ],
          });
          this.addsamplectarray.push(initialForm);
        } else {
          const initialForm: FormGroup = this.fb.group({
            type: [t.type],
            text: [t.text],
            country: [t.country],
            phno: [t.phno],
            urlType: [t.urlType],
            url: [t.url],
            urlvariable: [null],
          });
          this.addsamplectarray.push(initialForm);
        }
      });
    }
    if (
      this.templateContentForm.get('quickreplyBtns').value &&
      this.addsamplequickreplyarray.controls.length === 0
    ) {
      const btn_data = this.templateContentForm.get('quickreplyBtns').value;
      btn_data.forEach((t: any) => {
        const initialForm: FormGroup = this.fb.group({
          quickreplyText: [t.quickreplyText],
        });
        this.addsamplequickreplyarray.push(initialForm);
      });
    } else {
      this.addSampleForm
        .get('quickreplyBtns')
        .setValue(this.templateContentForm.get('quickreplyBtns').value);
    }

    if (this.content_dtls_value) {
      this.addSampleForm.patchValue({
        headerTemplateType: this.content_dtls_value.header?.header_type
          ? this.content_dtls_value.header?.header_type
          : null,
        uploadMedia:
          this.content_dtls_value.header &&
          this.content_dtls_value.header?.header_type === 'media'
            ? this.content_dtls_value.header.data[0].url
            : null,
      });
    }

    this.addSampleForm.patchValue({
      headerTemplateType: this.templateCreationForm.get('tempType').value,
      headertext: this.templateContentForm.get('tempHeaderText').value,
      headerMediaType: this.templateContentForm.get('tempHeaderMedia').value,
      bodyText: this.templateContentForm.get('tempBody').value,
      footerText: this.templateContentForm.get('footerText').value,
      buttontype: this.templateContentForm.get('buttontype').value,
    });

    this.store.dispatch(
      templateactions.addsamplePreview({ data: this.addSampleForm.value })
    );
  }

  validateDuplicateSelections() {
    return (control: FormControl) => {
      const selectedValue = control.value;
      if (selectedValue !== null) {
        const selectedIndexes = this.callToActionFormArray.controls
          .map((c, index) =>
            c.get('type').value === selectedValue ? index : -1
          )
          .filter((index) => index !== -1);

        if (selectedIndexes.length > 1) {
          return { duplicateSelection: true };
        }
      }
      return null;
    };
  }

  onAddButtons(data: any) {
    if (data === 'Call To Action') {
      if (this.callToActionFormArray.length === 2) {
        return;
      } else {
        const initialForm: FormGroup = this.fb.group({
          type: [null, this.validateDuplicateSelections()],
          text: [null, [Validators.required, this.cv.noWhitespace()]],
          country: [null],
          phno: [null],
          urlType: [null],
          url: [null],
        });
        this.callToActionFormArray.push(initialForm);
      }
    } else if (data === 'Quick Reply') {
      if (this.quickreplyFormArray.length === 3) {
        return;
      } else {
        const initialForm: FormGroup = this.fb.group({
          quickreplyText: [null, [Validators.required, this.cv.noWhitespace()]],
        });
        this.quickreplyFormArray.push(initialForm);
      }
    }
  }

  onDeleteButton(index: number, action: string) {
    if (action === 'calltoaction') {
      this.buttonsList.splice(index, 1);
      this.callToActionFormArray.removeAt(index);
    } else {
      this.buttonsList.splice(index, 1);
      this.quickreplyFormArray.removeAt(index);
    }
  }

  onActionButton(event: string, index: number) {
    const ctoaForm = this.callToActionFormArray.at(index) as FormGroup;
    if (!ctoaForm) return;
    const ctoaFormControls: {
      controls: string[];
      controlValidators: { [key: string]: string[] };
    } = {
      controls: ['urlType', 'url', 'phno', 'country'],
      controlValidators: {
        'Phone Number': ['phno', 'country'],
        'Visit Website': ['urlType', 'url'],
      },
    };
    ctoaFormControls.controls.forEach((control: string) => {
      ctoaForm
        .get(control)
        ?.setValidators(
          ctoaFormControls.controlValidators[event]?.includes(control)
            ? [Validators.required]
            : null
        );
      ctoaForm.get(control)?.updateValueAndValidity();
    });
  }

  editorOnInit(event: any) {
    this.shareservice.quillInstance = event.editor;
  }

  onAddVariable() {
    const currentContent = this.templateservice.formatData(
      this.shareservice.quillInstance.getContents().ops
    );
    const range = this.shareservice.quillInstance.getSelection();
    const variableCount = (currentContent.match(/{{\d+}}/g) || []).length + 1;
    const newVariable = ` {{${variableCount}}} `;

    if (range) {
      this.shareservice.quillInstance.updateContents(
        new Delta().retain(range.index).insert(newVariable)
      );
      this.shareservice.quillInstance.setSelection(
        range.index + newVariable.length
      );
      this.templateCourser = range.index + newVariable.length;
    } else {
      this.shareservice.quillInstance.updateContents(
        new Delta()
          .retain(this.shareservice.quillInstance.getLength() - 1) // Keep 'Hello '
          .insert(newVariable)
      );
      this.shareservice.quillInstance.setSelection(
        this.shareservice.quillInstance.getLength()
      );
      this.templateCourser = this.shareservice.quillInstance.getLength();
    }
    const newString = this.templateservice.formatData(
      this.shareservice.quillInstance.getContents().ops
    );
    this.templateContentForm.get('tempBody').setValue(newString);
    this.bodyCharCount = newString.length;
    this.onInputChange();
  }

  onInputChange() {
    const WhtsappText = this.templateservice.formatData(
      this.shareservice.quillInstance.getContents().ops
    );
    let count;
    if (WhtsappText.length && WhtsappText === '\n') {
      count = 0;
    } else {
      count = WhtsappText.length;
    }

    if (!WhtsappText.trim()) {
      count = 0;
    }

    if (WhtsappText.length > this.maxBodyCharCount) {
      let newText = this.templatebody;
      if (newText && newText !== '\n') {
        newText = this.templateservice.formatToHtml(newText);
        this.shareservice.quillInstance.setContents(
          this.shareservice.quillInstance.clipboard.convert(newText)
        );
        this.shareservice.quillInstance.setSelection(
          this.shareservice.quillInstance.getLength()
        );
        this.templatebody = this.templateservice.formatData(
          this.shareservice.quillInstance.getContents().ops
        );
        if (
          this.templateservice.formatData(
            this.shareservice.quillInstance.getContents().ops
          ) === '\n'
        ) {
          count = 0;
        } else {
          count = this.templateservice.formatData(
            this.shareservice.quillInstance.getContents().ops
          ).length;
        }
      } else {
        let newText = WhtsappText.slice(0, 1023);
        newText = this.templateservice.formatToHtml(newText);
        this.shareservice.quillInstance.setContents(
          this.shareservice.quillInstance.clipboard.convert(newText)
        );
        this.shareservice.quillInstance.setSelection(
          this.shareservice.quillInstance.getLength()
        );
        this.templatebody = this.templateservice.formatData(
          this.shareservice.quillInstance.getContents().ops
        );
        count = this.templateservice.formatData(
          this.shareservice.quillInstance.getContents().ops
        ).length;
      }
    }

    let currentContent = this.templateservice.formatData(
      this.shareservice.quillInstance.getContents().ops
    );

    if (!this.templateservice.validateUserText(currentContent)) {
      const range = this.shareservice.quillInstance.getSelection();
      let rmindx: number;
      if (range && range.index) {
        rmindx = range.index - 7;
      }
      //this.templateservice.validateUserText(currentContent).text;
      this.shareservice.quillInstance.updateContents(
        new Delta().retain(rmindx).delete(7)
      );
      this.shareservice.quillInstance.setSelection(rmindx);
      this.templateCourser =
        this.shareservice.quillInstance.getSelection().index;
      count = this.templateservice.formatData(
        this.shareservice.quillInstance.getContents().ops
      ).length;
    }
    currentContent = this.templateservice.formatData(
      this.shareservice.quillInstance.getContents().ops
    );

    const variables = currentContent?.match(/{{\d+}}/g) || [];

    if (
      variables.length > 0 &&
      !this.templateservice.isCorrectOrder(currentContent)
    ) {
      let newContent = this.templateservice.sortString(currentContent);
      newContent = this.templateservice.formatToHtml(newContent);
      this.shareservice.quillInstance.setContents(
        this.shareservice.quillInstance.clipboard.convert(newContent)
      );
      this.shareservice.quillInstance.setSelection(
        this.shareservice.quillInstance.getLength()
      );
      this.templatebody = this.templateservice.formatData(
        this.shareservice.quillInstance.getContents().ops
      );
      count = this.templateservice.formatData(
        this.shareservice.quillInstance.getContents().ops
      ).length;
    }
    this.templatebody = this.templateservice.formatData(
      this.shareservice.quillInstance.getContents().ops
    );
    this.bodyCharCount = count;
    if (this.templateCourser) {
      this.shareservice.quillInstance.setSelection(this.templateCourser);
      this.templateCourser = null;
    }

    if (count) {
      this.templateContentForm
        .get('tempBody')
        .setValue(
          this.templateservice.formatData(
            this.shareservice.quillInstance.getContents().ops
          )
        );
    } else {
      this.templateContentForm.get('tempBody').setValue(null);
    }
  }

  storeCourser() {
    this.templateCourser = this.shareservice.quillInstance.getSelection();
  }

  addHeaderVariables() {
    const variable_val = this.templateContentForm.get('tempHeaderText').value;
    const variables = variable_val?.match(/{{\d+}}/g) || [];

    if (variables.length === 0) {
      const cursorPosition = this.headerinp.nativeElement.selectionStart;
      const inputValue = this.headerinp.nativeElement.value;

      // Define the value you want to append
      const valueToAppend = '{{1}}';

      // Insert the value at the cursor position
      const newValue =
        inputValue.slice(0, cursorPosition) +
        valueToAppend +
        inputValue.slice(cursorPosition);

      // Update the input field value
      this.templateContentForm.get('tempHeaderText').setValue(newValue);
    }
  }

  addEmoji(event: EmojiEvent) {
    this.chatMessage = event.emoji.native;
    const range = this.templateCourser
      ? this.templateCourser
      : this.shareservice.quillInstance.getSelection();

    if (range) {
      this.shareservice.quillInstance.updateContents(
        new Delta().retain(range.index).insert(this.chatMessage)
      );
      this.shareservice.quillInstance.setSelection(
        range.index + this.chatMessage.length
      );
      this.templateCourser = range.index + this.chatMessage.length;
    } else {
      this.shareservice.quillInstance.updateContents(
        new Delta()
          .retain(this.shareservice.quillInstance.getLength() - 1) // Keep 'Hello '
          .insert(this.chatMessage)
      );
      this.shareservice.quillInstance.setSelection(
        this.shareservice.quillInstance.getLength()
      );
      this.templateCourser = this.shareservice.quillInstance.getLength();
    }

    this.onInputChange();
  }

  collapseEmoji() {
    if (this.isCollapsed) {
      this.isCollapsed = false;
    }
  }

  openEditTemplate(item: any) {
    this.editItem = item;

    const content_dtls = JSON.parse(
      typeof item.content === 'string' ? item.content : item.content
    );

    this.templateCreationForm.patchValue({
      tempName: item.template_name,
      tempLanguage: JSON.parse(item.languages)[0],
      tempCategory: this.titlecasePipe.transform(item.category),
      tempType: this.titlecasePipe.transform(item.type),
    });

    this.shareservice.tempType.next(this.titlecasePipe.transform(item.type));
    if (
      content_dtls.button_details &&
      content_dtls.button_details.buttons.length > 0
    ) {
      const buttons = content_dtls.button_details.buttons;
      if (content_dtls.button_details.type === 'call_to_action') {
        const formArray = this.callToActionFormArray;
        buttons.forEach((button: any) => {
          const initialForm: FormGroup = this.fb.group({
            type: [
              button.type_of_action === 'phone_no'
                ? 'Phone Number'
                : 'Visit Website',
              this.validateDuplicateSelections(),
            ],
            text: [
              button.button_text,
              [
                Validators.required,
                button.type_of_action === 'phone_no'
                  ? Validators.pattern(this.cv.nameRegex)
                  : this.cv.noWhitespace(),
              ],
            ],
            // country: [button.phone_no ? button.phone_no.slice(0, 2) : null],
            country: [
              button.country_code
                ? button.country_code.slice(1, 3)
                : button.phone_no
                ? button.phone_no.slice(1, 3)
                : null,
            ],
            // phno: [button.phone_no ? button.phone_no : null],
            phno: [
              button.phone_number
                ? button.phone_number
                : button.phone_no
                ? button.phone_no.substring(button.phone_no.indexOf('') + 3)
                : null,
              button.type_of_action === 'phone_no'
                ? [
                    Validators.required,
                    Validators.pattern(this.cv.contactRegex),
                  ]
                : '',
            ],
            urlType: [
              button.type_of_action === 'website'
                ? this.titlecasePipe.transform(button.url_type)
                : null,
            ],
            url: [
              button.type_of_action === 'website'
                ? button.url.split('/{{1}}')[0].trim()
                : null,
              button.type_of_action === 'website'
                ? [Validators.required, this.cv.noWhitespace()]
                : '',
            ],
            urlvariable: [
              button.url_type?.toLowerCase() == 'dynamic'
                ? button.website_example
                : null,
            ],
          });
          formArray.push(initialForm);
        });
      }

      if (content_dtls.button_details.type === 'quick_reply') {
        const formArray = this.quickreplyFormArray;
        buttons.forEach((button: any) => {
          const initialForm: FormGroup = this.fb.group({
            quickreplyText: [
              button.button_text,
              [Validators.required, this.cv.noWhitespace()],
            ],
          });
          formArray.push(initialForm);
        });
      }
    }

    this.templateContentForm.patchValue({
      tempHeaderText:
        content_dtls.header && content_dtls.header.header_type === 'text'
          ? content_dtls.header.data[0].text
          : null,
      tempHeaderMedia:
        content_dtls.header && content_dtls.header.header_type === 'media'
          ? content_dtls.header.data[0].type
          : null,
      tempBody: this.templateservice.formatToHtml(content_dtls.body.text),
      enabletempFooter: !!content_dtls.footer,
      footerText: content_dtls.footer ? content_dtls.footer.text : null,
      enabletempButtons:
        content_dtls.button_details &&
        content_dtls.button_details.buttons.length > 0,
      buttontype:
        content_dtls.button_details &&
        content_dtls.button_details.buttons.length > 0
          ? this.getbtnType(content_dtls.button_details.type)
          : null,
    });

    this.shareservice.quillInstance.setContents(
      this.shareservice.quillInstance.clipboard.convert(
        this.templateContentForm.get('tempBody').value
      )
    );

    if (
      this.templateservice.formatData(
        this.shareservice.quillInstance.getContents().ops
      ) === '\n'
    ) {
      this.templateContentForm.get('tempBody').setValue(null);
    }

    this.content_dtls_value = content_dtls;

    //  this.addSampleForm.patchValue({
    //   headerTemplateType: content_dtls.header?.header_type,
    //   uploadMedia:
    //     content_dtls.header && content_dtls.header?.header_type === 'media'
    //       ? content_dtls.header.data[0].url
    //       : null,
    // });

    if (content_dtls.header?.data[0]?.url) {
      // this.file = content_dtls.header.data[0].url;

      const fileName = content_dtls.header.data[0].url.split('/').pop()!;
      // Splitting the file name by "-"
      const fileParts: any = fileName.split('-').slice(2).join('-');
      this.file = fileParts;
    }

    this.store.dispatch(
      templateactions.createtemplatePayload({
        data: this.templateContentForm.value,
      })
    );

    if (this.editItem) {
      this.templateCreationForm.get('tempName').disable();
      this.templateCreationForm.get('tempLanguage').disable();
      this.templateCreationForm.get('tempCategory').disable();
      this.templateCreationForm.get('tempType').disable();
    }
  }

  getbtnType(data: any) {
    let typ_val: string;
    if (data === 'call_to_action') {
      typ_val = 'Call To Action';
    } else {
      typ_val = 'Quick Reply';
    }
    return typ_val;
  }

  saveSampleData() {
    const temp_payload = {
      steponeform: this.templateCreationForm.value,
      steptwoform: this.templateContentForm.value,
      sampledataform: this.addSampleForm.value,
    };
    this.templateservice.createTemplatePayload(temp_payload);
    this.modalService.dismissAll();
  }

  saveDraftTemplate() {
    this.isSaveToDraft = true;
    this.submitTemplate();
  }

  submitTemplate() {
    let headerData: any;
    let bodyData: any;
    let btnData: any;
    let contentData: any = {};
    const header_Variables: any = [];
    const body_Var: any = [];

    if (this.templateCreationForm.get('tempType').value === 'Text') {
      const currentContent =
        this.templateContentForm.get('tempHeaderText').value;
      const header_var = (currentContent?.match(/{{\d+}}/g) || [])?.length;
      if (header_var > 0) {
        this.addSampleForm.get('headerVariables').value.forEach((t: any) => {
          header_Variables.push({ '1': t.variableName });
        });
        headerData = {
          header_type: this.templateCreationForm
            .get('tempType')
            .value.toLowerCase(),
          data: [
            {
              type: this.templateCreationForm
                .get('tempType')
                .value.toLowerCase(),
              text: this.templateContentForm.get('tempHeaderText').value,
              example: header_Variables[0]['1'],
            },
          ],
          variables: [
            {
              '1': 'var1',
            },
          ],
          examples: header_Variables,
        };
      } else {
        headerData = {
          header_type: this.templateCreationForm
            .get('tempType')
            .value.toLowerCase(),
          data: [
            {
              type: this.templateCreationForm
                .get('tempType')
                .value.toLowerCase(),
              text: this.templateContentForm.get('tempHeaderText').value,
            },
          ],
        };
      }
    } else if (this.templateCreationForm.get('tempType').value === 'Media') {
      headerData = {
        header_type: this.templateCreationForm
          .get('tempType')
          .value.toLowerCase(),
        data: [
          {
            type: this.templateContentForm
              .get('tempHeaderMedia')
              .value.toLowerCase(),
            url: this.addSampleForm.get('uploadMedia').value,
          },
        ],
      };
    }

    if (this.bodyVariableArray.controls.length > 0) {
      this.addSampleForm.get('bodyVariables').value.forEach((t: any) => {
        const number = t.variable.match(/\d+/); // Extract the number from '{{N}}'
        if (number) {
          const variableObj: any = {};
          variableObj[number[0]] = t.variableName;
          body_Var.push(variableObj);
        }
      });
    }

    const currentContent: any = this.shareservice.quillInstance
      .getText()
      .replace(/(<([^>]+)>)/gi, '');
    const variableCount = (currentContent.match(/{{\d+}}/g) || []).length;
    if (variableCount > 0) {
      const data = currentContent.match(/{{\d+}}/g) || [];
      const body_variables: any = [];
      data.forEach((t: string) => {
        const number = t.match(/\d+/); // Extract the number from '{{N}}'
        if (number) {
          const variableObj: any = {};
          variableObj[number[0]] = 'var' + number[0];
          body_variables.push(variableObj);
        }
      });
      bodyData = {
        text: this.templateservice.formatData(
          this.shareservice.quillInstance.getContents().ops
        ),
        variables: body_variables,
        examples: body_Var,
      };
    } else {
      bodyData = {
        text: this.templateservice.formatData(
          this.shareservice.quillInstance.getContents().ops
        ),
      };
    }

    if (
      this.templateContentForm.get('buttontype')?.value === 'Call To Action' &&
      this.addsamplectarray.controls?.length > 0
    ) {
      const buttons_data: any = [];
      this.addsamplectarray.controls.forEach((t) => {
        if (t.get('type')?.value === 'Phone Number') {
          buttons_data.push({
            type_of_action: 'phone_no',
            button_text: t.get('text')?.value,
            phone_no: '+' + t.get('country')?.value + t.get('phno')?.value,
            country_code: '+' + t.get('country')?.value,
            phone_number: t.get('phno')?.value,
          });
        } else if (
          t.get('type')?.value === 'Visit Website' &&
          t.get('urlType')?.value === 'Dynamic'
        ) {
          const cleanedString = t.get('url')?.value.replace(/{{\d+}}/g, '');
          const remainingPart = t
            .get('urlvariable')
            ?.value.replace(cleanedString, '');
          buttons_data.push({
            type_of_action: 'website',
            button_text: t.get('text')?.value,
            url_type: t.get('urlType')?.value.toLowerCase(),
            url: t.get('url')?.value.endsWith('/')
              ? t.get('url')?.value + '{{1}}'
              : t.get('url')?.value + '/{{1}}',
            website_example: t.get('urlvariable')?.value,
            variables: { 1: remainingPart },
          });
        } else if (
          t.get('type')?.value === 'Visit Website' &&
          t.get('urlType')?.value === 'Static'
        ) {
          buttons_data.push({
            type_of_action: 'website',
            button_text: t.get('text')?.value,
            url_type: t.get('urlType')?.value.toLowerCase(),
            url: t.get('url')?.value,
          });
        }
      });
      btnData = {
        type: 'call_to_action',
        buttons: buttons_data,
      };
    }

    if (
      this.templateContentForm.get('buttontype')?.value === 'Quick Reply' &&
      this.quickreplyFormArray.controls.length > 0
    ) {
      const buttons_data: any = [];
      this.quickreplyFormArray.controls.forEach((t) => {
        buttons_data.push({
          button_text: t.get('quickreplyText')?.value,
        });
      });
      btnData = {
        type: 'quick_reply',
        buttons: buttons_data,
      };
    }

    contentData = {
      header: headerData,
      body: bodyData,
    };

    if (this.templateContentForm.get('enabletempFooter').value === true) {
      contentData['footer'] = {
        text: this.templateContentForm.get('footerText').value,
      };
    }

    if (this.templateContentForm.get('enabletempButtons').value === true) {
      contentData['button_details'] = btnData;
    }

    this.loader.show();

    if (
      this.editItem &&
      (this.editItem.status === 'REJECTED' ||
        this.editItem.status === 'DRAFTED')
    ) {
      const edit_template_payload = {
        id: this.editItem.id,
        account_id: this.account_dtls.account_id,
        bsp_id: this.account_dtls.bsp_id,
        waba_number: this.account_dtls.waba_no,
        template_name: this.templateCreationForm.get('tempName').value,
        languages: [this.templateCreationForm.get('tempLanguage').value],
        category: this.templateCreationForm
          .get('tempCategory')
          .value.toUpperCase(),
        status: this.editItem.status,
        type: this.templateCreationForm.get('tempType').value,
        is_drafted: this.isSaveToDraft,
        content: contentData,
      };

      this.store.dispatch(
        templateactions.updatetemplate({
          data: edit_template_payload,
          id: this.editItem.id,
        })
      );
    } else {
      const create_template_payload = {
        account_id: this.account_dtls.account_id,
        bsp_id: this.account_dtls.bsp_id,
        waba_number: this.account_dtls.waba_no,
        template_name: this.templateCreationForm.get('tempName').value,
        languages: [this.templateCreationForm.get('tempLanguage').value],
        category: this.templateCreationForm
          .get('tempCategory')
          .value.toUpperCase(),
        type: this.templateCreationForm.get('tempType').value,
        is_drafted: this.isSaveToDraft,
        content: contentData,
      };

      this.store.dispatch(
        templateactions.createtemplate({ data: create_template_payload })
      );
    }
  }

  goToTemplateList() {
    this.gotoListing();
    this.editItem = null;
    this.file = null;
    this.stepper.reset();
    this.resetForm();
  }

  ontemplateBtn(event: string) {
    const controlName =
      event === 'Call To Action' ? 'ctoaButtons' : 'quickreplyBtns';
    if (controlName === 'ctoaButtons') {
      this.quickreplyFormArray.clear();
      this.addsamplequickreplyarray.clear();
    } else if (controlName === 'quickreplyBtns') {
      this.callToActionFormArray.clear();
      this.addsamplectarray.clear();
    }
    this.templateContentForm.setControl(
      controlName,
      this.fb.array([], Validators.required)
    );
    if (controlName === 'ctoaButtons') {
      const initialForm: FormGroup = this.fb.group({
        type: [null, this.validateDuplicateSelections()],
        text: [null, Validators.required],
        country: [null],
        phno: [null],
        urlType: [null],
        url: [null],
      });
      this.callToActionFormArray.push(initialForm);
      this.templateContentForm.setControl('quickreplyBtns', this.fb.array([]));
    } else if (controlName === 'quickreplyBtns') {
      const initialForm: FormGroup = this.fb.group({
        quickreplyText: [null, Validators.required],
      });
      this.quickreplyFormArray.push(initialForm);
      this.templateContentForm.setControl('ctoaButtons', this.fb.array([]));
    }

    this.templateContentForm
      .get(controlName === 'ctoaButtons' ? 'quickreplyBtns' : 'ctoaButtons')
      ?.clearValidators();
    this.templateContentForm.updateValueAndValidity();
  }

  onbtnChange(event: any, i: number) {
    this.callToActionFormArray.at(i).get('text').clearValidators();
    this.callToActionFormArray.at(i).get('text').updateValueAndValidity();
    this.callToActionFormArray.at(i).get('country').clearValidators();
    this.callToActionFormArray.at(i).get('country').updateValueAndValidity();
    this.callToActionFormArray.at(i).get('phno').clearValidators();
    this.callToActionFormArray.at(i).get('phno').updateValueAndValidity();
    this.callToActionFormArray.at(i).get('urlType').clearValidators();
    this.callToActionFormArray.at(i).get('urlType').updateValueAndValidity();
    this.callToActionFormArray.at(i).get('url').clearValidators();
    this.callToActionFormArray.at(i).get('url').updateValueAndValidity();

    if (event === 'Phone Number') {
      this.callToActionFormArray
        .at(i)
        .get('text')
        .setValidators([
          Validators.required,
          Validators.pattern(this.cv.nameRegex),
        ]);
      this.callToActionFormArray
        .at(i)
        .get('country')
        .setValidators(Validators.required);
      this.callToActionFormArray
        .at(i)
        .get('phno')
        .setValidators([
          Validators.required,
          Validators.pattern(this.cv.contactRegex),
        ]);

      this.callToActionFormArray.at(i).updateValueAndValidity();
    } else if (event === 'Visit Website') {
      this.callToActionFormArray
        .at(i)
        .get('text')
        .setValidators(Validators.required);
      this.callToActionFormArray
        .at(i)
        .get('urlType')
        .setValidators(Validators.required);
      this.callToActionFormArray
        .at(i)
        .get('url')
        .setValidators(Validators.required);
      this.callToActionFormArray.at(i).updateValueAndValidity();
    }

    this.cd.detectChanges();
  }

  btnAction(data: any) {
    if (data.type === 'Phone Number') {
      window.open('tel:+' + data.phno);
    } else if (data.type === 'Visit Website') {
      window.open(data.url);
    }
  }

  onSelect(event: any) {
    const file = event.target.files[0];

    if (!file) return;

    const fileType = file.type;
    const fileSizeMB = file.size / (1024 * 1024);

    if (
      ((fileType === 'video/mp4' || fileType === 'video/3gpp') &&
        fileSizeMB > 16) ||
      ((fileType === 'audio/mp4' ||
        fileType === 'audio/ogg;codecs=opus' ||
        fileType === 'audio/mpeg' ||
        fileType === 'audio/amr' ||
        fileType === 'audio/aac') &&
        fileSizeMB > 16) ||
      (fileType === 'application/pdf' && fileSizeMB > 100) ||
      ((fileType === 'image/jpeg' || fileType === 'image/png') &&
        fileSizeMB > 5)
    ) {
      let warningMessage = '';

      if (
        (fileType === 'video/mp4' || fileType === 'video/3gpp') &&
        fileSizeMB > 16
      ) {
        warningMessage =
          "You can't upload video files which are greater than 16MB";
      } else if (
        (fileType === 'audio/mp4' ||
          fileType === 'audio/ogg;codecs=opus' ||
          fileType === 'audio/mpeg' ||
          fileType === 'audio/amr' ||
          fileType === 'audio/aac') &&
        fileSizeMB > 16
      ) {
        warningMessage =
          "You can't upload audio files which are greater than 16MB";
      } else if (
        (fileType === 'application/pdf' ||
          fileType ===
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document') &&
        fileSizeMB > 100
      ) {
        warningMessage =
          "You can't upload documents which are greater than 100MB";
      } else if (
        (fileType === 'image/jpeg' || fileType === 'image/png') &&
        fileSizeMB > 5
      ) {
        warningMessage = "You can't upload images which are greater than 5MB";
      }

      this.alertMsg.alertWarning('Warning!', warningMessage);
      return;
    }

    this.file = file;

    this.store.dispatch(
      commonactions.initiateuploadFile({
        file: this.file,
        accountid: this.account_dtls.account_id,
        mobileno: this.account_dtls.waba_no,
        channel: this.account_dtls.channel,
        createdby: this.account_dtls.createdby,
        is_active: true,
      })
    );
  }

  deleteVariables(index: number, data: any) {
    let str = this.shareservice.quillInstance.getText();
    str = str.replace(data, '');
    this.shareservice.quillInstance.setText(str);
    let currentContent = this.shareservice.quillInstance.getText();
    const variables = currentContent?.match(/{{\d+}}/g) || [];
    for (let i = 0; i < variables.length; i++) {
      const variable = `{{${i + 1}}}`;
      currentContent = currentContent.replace(variables[i], variable);
    }
    this.shareservice.quillInstance.setText(currentContent);
  }

  deleteHeaderVariables(index: number) {
    let str = this.templateContentForm.get('tempHeaderText').value;
    str = str.replace(`{{${index + 1}}}`, '');
    this.templateContentForm.controls['tempHeaderText'].setValue(str);
  }

  formatFileSize = (size: any) => {
    const kiloByte = 1024;
    const megaByte = kiloByte * 1024;
    const gigaByte = megaByte * 1024;

    if (size >= gigaByte) {
      return `${(size / gigaByte).toFixed(2)} GB`;
    } else if (size >= megaByte) {
      return `${(size / megaByte).toFixed(2)} MB`;
    } else if (size >= kiloByte) {
      return `${(size / kiloByte).toFixed(2)} KB`;
    } else {
      return `${size} bytes`;
    }
  };

  stepChanged(event: StepperSelectionEvent) {
    if (
      event.previouslySelectedIndex &&
      event.previouslySelectedIndex == 2 &&
      event.selectedIndex == 1
    ) {
      this.clearAddSampleData();
    }
    if (event.selectedIndex == 2) {
      this.addSampleData();
    }
    // if (event.selectedIndex == 4) {
    //   this.submitTemplate();
    // }

    // Do something when a step is clicked
    // const selectedStep: MatStepper = event.selectedStep;
  }

  ngAfterContentChecked(): void {
    this.cd.detectChanges();
  }

  ngOnDestroy() {
    this.channelSub.unsubscribe();
    this.store.dispatch(templateactions.resetTemplateData());
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
