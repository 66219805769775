import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject, takeUntil } from 'rxjs';
import { SharedService } from '../services/shared.service';
import * as common from '../store/common.actions';
import { selectSsoProfilesDtlsData } from '../store/common.selectors';
@Component({
  selector: 'app-sso-validation',
  templateUrl: './sso-validation.component.html',
  styleUrls: ['./sso-validation.component.scss'],
})
export class SsoValidationComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  clientId: string;
  token: string;
  constructor(
    private route: Router,
    private ActivatedRoute: ActivatedRoute,
    private readonly store: Store,
    private shareservice: SharedService
  ) {}
  ngOnInit(): void {
    localStorage.clear();
    const params: any = this.ActivatedRoute.snapshot.queryParams;
    this.store
      .select(selectSsoProfilesDtlsData)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.clientId = res.clientId;
        this.token = res.token;
        if (res.loggedInUserDetails) {
          if (
            typeof res.loggedInUserDetails.account.licenses.license_data[0]
              .channel_credentials == 'string'
          ) {
            return this.route.navigate(['support-admin']);
          }

          this.shareservice.selectedBA$.next(
            res.loggedInUserDetails.account.licenses.license_data[0]
          );
          if (params.redirect_url) {
            this.route.navigateByUrl(params.redirect_url);
          } else if (res.loggedInUserDetails.profile) {
            this.route.navigate([
              'customer',
              res.loggedInUserDetails.profile.id,
              'dashboard',
            ]);
          }
        }
      });

    if (params.jwtToken && params.clientId) {
      //set token
      this.store.dispatch(
        common.SsoActions.startSsoActions({
          jwtToken: window.atob(params.jwtToken),
          clientId: window.atob(params.clientId),
        })
      );
    } else {
      if (this.clientId === null && this.token === null) {
        this.store.dispatch(
          common.SsoActions.startSsoActions({
            jwtToken:
              'eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJtNUNOd0JQYTVNZVpyMUlRb1MtSENiY2hENlpZT0VURkNyVFp1V3RtQ1g4In0.eyJleHAiOjE3MjE3OTU0NzcsImlhdCI6MTcyMTc1OTQ3NywianRpIjoiNjgwYjk2YTktNGU0MS00OWIzLTg2ZTEtN2JkZWI2OWMwMzVjIiwiaXNzIjoiaHR0cDovL2tleWNsb2FrLmtleWNsb2FrLnN2Yy5jbHVzdGVyLmxvY2FsOjgwODAvcmVhbG1zL2RlZXBmb3JnZSIsImF1ZCI6ImFjY291bnQiLCJzdWIiOiIyOTgxNWJjNi0xYWYxLTQyOTktYmFhNC0xOGU2NTkyYWNhYjUiLCJ0eXAiOiJCZWFyZXIiLCJhenAiOiJzbWFydHZhdWx0Iiwic2lkIjoiYTAyZTk3YmUtYzkzMS00Mzg0LTgwZTctNGZiZmJlMmZmNDM5IiwiYWNyIjoiMSIsInJlYWxtX2FjY2VzcyI6eyJyb2xlcyI6WyJvZmZsaW5lX2FjY2VzcyIsImRlZmF1bHQtcm9sZXMtZGVlcGZvcmdlIiwidW1hX2F1dGhvcml6YXRpb24iXX0sInJlc291cmNlX2FjY2VzcyI6eyJhY2NvdW50Ijp7InJvbGVzIjpbIm1hbmFnZS1hY2NvdW50IiwibWFuYWdlLWFjY291bnQtbGlua3MiLCJ2aWV3LXByb2ZpbGUiXX19LCJzY29wZSI6ImVtYWlsIHByb2ZpbGUiLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwibmFtZSI6Im9jbXAgYWRtaW4iLCJwcmVmZXJyZWRfdXNlcm5hbWUiOiJvY21wYWRtaW5AdGVzdC5jb20iLCJnaXZlbl9uYW1lIjoib2NtcCIsImZhbWlseV9uYW1lIjoiYWRtaW4iLCJlbWFpbCI6Im9jbXBhZG1pbkB0ZXN0LmNvbSJ9.MnkycBlZOYcUtpfbUMgWQGIdXQQqAyQjtPKZJMypc3YwVGTz2Q_6xDFd7cCHNIDv-ag7BfKGX2uu_XADtVMKHKTjqozCnK1T6PIanJrfLNcvKvycLaJjxPR4i1XBCcgVonmxHbWQxH70Y8uSTd96j3-PmNJsdFTXFyLuQMwfflaLQsw2z7DGb5vDqx7DaHF1Adc0Q0917QU6bLT2c33FytKXrEoGZapOBQOWecOuV1qwmNJRfYyxuqx5cnJsAeEcFALlJ3y3wyCzo3ce2a-OQufYnXwmNRPwJoTWCgxOjLKj4Td15aIQ6X3bSv8-Ae4j9fAeiY1QwN3-luFwW92OAQ',
            clientId: 'ocmp-test',
          })
        );
      }
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
