import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SsoService } from '../services/sso.service';

@Component({
  selector: 'app-client-redirect',
  templateUrl: './client-redirect.component.html',
  styleUrls: ['./client-redirect.component.scss'],
})
export class ClientRedirectComponent implements OnInit {
  constructor(
    private router: Router,
    private ssoservice: SsoService,
    private ActivatedRoute: ActivatedRoute
  ) {}
  ngOnInit(): void {
    const url = this.router.url.split('?')[0];
    const matchers = ['cc', 'customer'];
    const params: any = this.ActivatedRoute.snapshot.queryParams;
    const spliturl = url.split('/');
    const id = decodeURIComponent(spliturl[1]);
    if (spliturl.length > 3 && params.jwtToken && id) {
      const token = this.ssoservice.decodeToken(window.atob(params.jwtToken));
      // Step 1: Remove the first two elements
      const remainingElements = spliturl.slice(2);

      // Step 2: Join the remaining elements with '/'
      const result = remainingElements.join('/');
      if (
        token.sub &&
        token.sub == spliturl[3] &&
        matchers.includes(spliturl[2])
      ) {
        //logical routing
        this.router.navigate(['/login'], {
          queryParams: {
            jwtToken: params.jwtToken,
            clientId: id,
            redirect_url: result,
          },
        });
      } else if (params.jwtToken) {
        //login
        this.router.navigate(['/login'], {
          queryParams: { jwtToken: params.jwtToken, clientId: id },
        });
      }
    } else if (params.jwtToken && id) {
      if(spliturl[1]=="reports" && params.clientId){
        const token=this.ssoservice.decodeToken(window.atob(params.jwtToken));
       const result= `/customer/${token.sub}/reports`
        this.router.navigate(['/login'], {
          queryParams: { jwtToken: params.jwtToken, clientId: params.clientId , redirect_url: result},
        });
      }
      else{
        //login
        this.router.navigate(['/login'], {
          queryParams: { jwtToken: params.jwtToken, clientId: id },
        });
      }
    } else {
      this.router.navigate(['page-not-found']);
    }
  }
}
