/* eslint-disable no-prototype-builtins */
import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Params, Router, ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject, takeUntil } from 'rxjs';
import * as roleactions from 'src/app/control-center/role-management/store/role.actions';
import { skeleton_data } from 'src/app/customer/whatsapp-preview/whatsapp-preview.data';
import { selectRoleDataDtls } from '../store/role.selectors';
import { AlertService } from 'src/app/alert/alert.service';
import {
  selectProfilesDtlsData,
  selectSsoProfilesDtlsData,
} from 'src/app/store/common.selectors';
import { LoaderService } from 'src/app/services/loader.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-view-role',
  templateUrl: './view-role.component.html',
  styleUrls: ['./view-role.component.scss'],
})
export class ViewRoleComponent implements OnInit, AfterViewChecked, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  viewRoleItem: any;
  rolePermissions: any;
  userDetails: any;
  skeletonData = skeleton_data;
  displayTemplate: TemplateRef<string>;
  singleRoles: any = {};
  multiRoles: any = {};

  @ViewChild('roletemp') private roletemp: TemplateRef<string>;
  @ViewChild('datawaiting') private datawaiting: TemplateRef<string>;

  constructor(
    private readonly store: Store,
    public loader: LoaderService,
    private cd: ChangeDetectorRef,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private alertMsg: AlertService
  ) {}

  ngOnInit(): void {
    if (this.router.url.includes('view')) {
      this.activatedRoute.params.subscribe((params: Params) => {
        const viewrole_id = params['id'];
        this.store.dispatch(roleactions.viewRoleDetails({ id: viewrole_id }));
      });
    }
    if (environment.sso) {
      this.store
        .select(selectSsoProfilesDtlsData)
        .pipe(takeUntil(this.destroy$))
        .subscribe((res) => {
          if (res.loggedInUserDetails) {
            this.userDetails = res.loggedInUserDetails;
          }
        });
    } else {
      this.store
        .select(selectProfilesDtlsData)
        .pipe(takeUntil(this.destroy$))
        .subscribe((res) => {
          if (res.loggedInUserDetails) {
            this.userDetails = res.loggedInUserDetails;
          }
        });
    }
  }

  ngAfterViewChecked(): void {
    this.displayTemplate = this.datawaiting;
    this.store
      .select(selectRoleDataDtls)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: any) => {
        if (res.viewroleRes && res.viewroleRes.status_code === 200) {
          this.viewRoleItem = res.viewroleRes.data;
          const { singleKeyObj, objectKeyObj } = this.extractObjects(
            this.viewRoleItem.rbac_policies
          );
          this.singleRoles = singleKeyObj;
          this.multiRoles = objectKeyObj;

          if (typeof this.viewRoleItem.rbac_policies === 'string') {
            this.rolePermissions = JSON.parse(this.viewRoleItem.rbac_policies);
          } else {
            this.rolePermissions = this.viewRoleItem.rbac_policies;
          }
          this.displayTemplate = this.roletemp;
        } else if (res.error) {
          const dispErr = res.error.message;
          this.alertMsg.alertDanger('Error', dispErr);
        }
      });
    this.cd.detectChanges();
  }

  extractObjects(data: any) {
    const singleKeyObj: any = {};
    const objectKeyObj: any = {};

    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        if (typeof data[key] === 'boolean' && data[key] === true) {
          singleKeyObj[key] = data[key];
        } else if (
          data[key].can_create === true ||
          data[key].can_view === true ||
          data[key].can_update === true ||
          data[key].can_delete === true
        ) {
          objectKeyObj[key] = data[key];
        }
      }
    }

    return { singleKeyObj, objectKeyObj };
  }

  gotoListing() {
    this.router.navigate(['cc', this.userDetails.profile.id, 'roles']);
    this.viewRoleItem = null;
  }

  getControls(data: any) {
    const permissionObj = [];
    for (const key in data) {
      if (
        data.hasOwnProperty(key) &&
        data[key].hasOwnProperty('full_control') &&
        data[key].full_control === true
      ) {
        permissionObj.push(key);
      }
      if (data.hasOwnProperty(key) && data[key] === true) {
        permissionObj.push(key);
      }
    }
    const totalcount = Object.keys(data).length;
    const permissionscount = permissionObj.length;
    return `(Controls: ${permissionscount}/${totalcount})`;
  }

  onEditRole(data: any) {
    this.router.navigate([
      'cc',
      this.userDetails.profile.id,
      'roles',
      data.id,
      'edit',
    ]);
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
