// Interface Declarations
export interface Channels {
  ch_id: number;
  ch_name: string;
  ch_icon: string;
  ch_description: string;
  isdefault: boolean;
  isenabled: boolean;
  disabled: boolean;
}

export interface BSP {
  bsp_id: number;
  bsp_name: string;
  bsp_logo: string;
  bsp_description: string;
  isdefault: boolean;
  assigned_numbers: number;
  linked_channel: string;
  created_on: string;
  updated_on: string;
}

export interface WabaID {
  bact_id: number;
  bact_icon: string;
  bact_name: string;
  msisdn: string;
  waba_id: number;
  waba_status: string;
  waba_quality: string;
  number_quality: string;
  number_status: string;
  isActive: boolean;
  verified: boolean;
  isdefault: boolean;
  bsp: string;
  channel: string;
  created_on: string;
  updated_on: string;
}

export interface UserSelection {
  channel: string;
  bsp: string;
  bact: string;
  msisdn: string;
}

export interface CampaignListTableData {
  ischecked: boolean;
  name: string;
  id: number;
  category: string;
  template: string;
  status: string;
  tags: string;
  starttime: string;
  endtime: string;
  scheduled: string;
}

export interface CampaignViewTableData {
  mobile_number: string;
  message_id: string;
  sent_date: string;
  status: string;
}

export interface AllBusinessSettings {
  name: string;
  profileUrl: string;
  email: string;
  industry: string;
  desc: string;
  address: string;
  callBackUrl: string;
  website: string;
  whatsappNumber: number;
  fbMID: string;
  associateName: string;
  sourceID: string;
  apiKey: string;
  deliveryUrl: string;
  defaultMsg: string;
}

export interface InitalSummaryReportsData {
  marketing: SummaryReport;
  utility: SummaryReport;
  authentication: SummaryReport;
  total: SummaryReport;
}

export interface SummaryReport {
  submitted: number;
  sent: number;
  optout: number;
  delivered: number;
  read: number;
  failed: number;
  pending: number;
  unread: number;
}

// END Interface Declarations

// Channels Data
export const channels: Channels[] = [
  {
    ch_id: 1,
    ch_name: 'WhatsApp Business API',
    ch_icon: 'assets/img/whatsapp.svg',
    ch_description:
      'when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
    isdefault: true,
    isenabled: true,
    disabled: false,
  },
  {
    ch_id: 2,
    ch_name: 'Google Messages',
    ch_icon: 'assets/img/rcs.svg',
    ch_description:
      'when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
    isdefault: false,
    isenabled: false,
    disabled: true,
  },
  {
    ch_id: 3,
    ch_name: 'Instagram',
    ch_icon: 'assets/img/insta.svg',
    ch_description:
      'when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
    isdefault: false,
    isenabled: false,
    disabled: true,
  },
  {
    ch_id: 4,
    ch_name: 'Telegram',
    ch_icon: 'assets/img/telegram.svg',
    ch_description:
      'when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
    isdefault: false,
    isenabled: false,
    disabled: true,
  },
  {
    ch_id: 5,
    ch_name: 'Email',
    ch_icon: 'assets/img/email.svg',
    ch_description:
      'when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
    isdefault: false,
    isenabled: false,
    disabled: true,
  },
  {
    ch_id: 6,
    ch_name: 'SMS',
    ch_icon: 'assets/img/sms.svg',
    ch_description:
      'when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
    isdefault: false,
    isenabled: false,
    disabled: true,
  },
];
// END Channels Data

// BSP Data
export const bsp: BSP[] = [
  {
    bsp_id: 1,
    bsp_name: 'Routemobile',
    bsp_logo: 'assets/img/rml.svg',
    bsp_description:
      'when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.',
    isdefault: true,
    linked_channel: 'WhatsApp Business API',
    assigned_numbers: 1,
    created_on: 'June 26, 2023',
    updated_on: 'July 30, 2023',
  },
  // {
  //   bsp_id: 2,
  //   bsp_name: 'Netcore',
  //   bsp_logo: 'assets/img/netcore.svg',
  //   bsp_description: 'some description about the bsp',
  //   isdefault: false,
  //   linked_channel: 'WhatsApp Business API',
  //   assigned_numbers: 3,
  //   created_on: 'June 26, 2023',
  //   updated_on: 'July 30, 2023',
  // },
  // {
  //   bsp_id: 3,
  //   bsp_name: 'Nuacem',
  //   bsp_logo: 'assets/img/nuacem.svg',
  //   bsp_description: 'some description about the bsp',
  //   isdefault: false,
  //   linked_channel: 'WhatsApp Business API',
  //   assigned_numbers: 5,
  //   created_on: 'June 26, 2023',
  //   updated_on: 'July 30, 2023',
  // },
  // {
  //   bsp_id: 4,
  //   bsp_name: 'Nuacem',
  //   bsp_logo: 'assets/img/nuacem.svg',
  //   bsp_description: 'some description about the bsp',
  //   isdefault: false,
  //   linked_channel: 'Google Messages',
  //   assigned_numbers: 5,
  //   created_on: 'June 26, 2023',
  //   updated_on: 'July 30, 2023',
  // },
  // {
  //   bsp_id: 5,
  //   bsp_name: 'Nuacem',
  //   bsp_logo: 'assets/img/nuacem.svg',
  //   bsp_description: 'some description about the bsp',
  //   isdefault: false,
  //   linked_channel: 'Instagram',
  //   assigned_numbers: 5,
  //   created_on: 'June 26, 2023',
  //   updated_on: 'July 30, 2023',
  // },
];
// END BSP Data

// WABAID/Business Accounts Data
export const wabaid: WabaID[] = [
  {
    bact_id: 1,
    bact_icon: 'assets/img/chola.svg',
    bact_name: 'Cholamandalam Finance',
    msisdn: '+91 9912341590',
    waba_id: 435711037685804,
    waba_status: 'Connected',
    waba_quality: 'Connected',
    number_quality: 'Medium',
    number_status: 'Connected',
    isActive: true,
    verified: true,
    isdefault: true,
    bsp: 'rml',
    channel: 'whatsapp',
    created_on: 'June 26, 2023',
    updated_on: 'July 30, 2023',
  },
  // {
  //   bact_id: 2,
  //   bact_icon: 'assets/img/chola.svg',
  //   msisdn: '+91 90000 900000',
  //   bact_name: 'Cholamandalam Housing Finance',
  //   verified: true,
  //   isdefault: false,
  //   bsp: 'rml',
  //   channel: 'whatsapp',
  //   created_on: 'June 05, 2023',
  //   updated_on: 'July 10, 2023',
  // },
  // {
  //   bact_id: 3,
  //   bact_icon: 'assets/img/chola.svg',
  //   msisdn: '+91 90000 900000',
  //   bact_name: 'Cholamandalam vehicle Finance',
  //   verified: true,
  //   isdefault: false,
  //   bsp: 'rml',
  //   channel: 'whatsapp',
  //   created_on: 'June 02, 2023',
  //   updated_on: 'July 06, 2023',
  // },
];
// END WABAID/Business Accounts Data

// Userselection Data
export const userSelected: UserSelection = {
  channel: 'WhatsApp Business API',
  bsp: 'Routemobile',
  bact: 'Cholamandalam Finance',
  msisdn: '+91 9912341590',
};
// END Userselection Data

// Campaigns List Data
export const campaigndata: CampaignListTableData[] = [
  {
    ischecked: false,
    name: 'Credit_campaign',
    id: 3456790,
    category: 'Transaction',
    template: 'media_template',
    status: 'In-Progress',
    tags: 'media',
    starttime: '22/6/2023,15:03',
    endtime: '--/--/----,--:--',
    scheduled: 'Instant',
  },
  {
    ischecked: false,
    name: 'Media_campaign',
    id: 3456789,
    category: 'Transaction',
    template: 'media_template',
    status: 'In-Progress',
    tags: 'media',
    starttime: '22/6/2023,15:03',
    endtime: '--/--/----,--:--',
    scheduled: '22/6/2023, 15:03',
  },
  {
    ischecked: false,
    name: 'Offer26',
    id: 3456788,
    category: 'Transaction',
    template: 'media_template',
    status: 'Failed',
    tags: 'media',
    starttime: '22/6/2023,15:03',
    endtime: '--/--/----,--:--',
    scheduled: '22/6/2023, 15:03',
  },
  {
    ischecked: false,
    name: 'Channel_upgrade',
    id: 3456787,
    category: 'Transaction',
    template: 'media_template',
    status: 'Draft',
    tags: 'media',
    starttime: '22/6/2023,15:03',
    endtime: '--/--/----,--:--',
    scheduled: '--/--/----,--:--',
  },
  {
    ischecked: false,
    name: 'Transaction_campaign',
    id: 3456786,
    category: 'Transaction',
    template: 'media_template',
    status: 'Paused',
    tags: 'media',
    starttime: '22/6/2023,15:03',
    endtime: '--/--/----,--:--',
    scheduled: '22/6/2023, 15:03',
  },
  {
    ischecked: false,
    name: 'Campaign_Name',
    id: 3456785,
    category: 'Transaction',
    template: 'media_template',
    status: 'Success',
    tags: 'media',
    starttime: '22/6/2023,15:03',
    endtime: '--/--/----,--:--',
    scheduled: '22/6/2023, 15:03',
  },
];
// END Campaigns List Data

// Campaign Individual View Data
export const campaignViewData: CampaignViewTableData[] = [
  {
    mobile_number: '+91-8978787654',
    message_id: 'https://wa.me/+919872461320',
    sent_date: '22/6/2023,15:03',
    status: 'Pending',
  },
  {
    mobile_number: '+91-8978787654',
    message_id: 'https://wa.me/+919872461320',
    sent_date: '22/6/2023,15:03',
    status: 'Delivered',
  },
  {
    mobile_number: '+91-8978787654',
    message_id: 'https://wa.me/+919872461320',
    sent_date: '22/6/2023,15:03',
    status: 'Opt-Out',
  },
  {
    mobile_number: '+91-8978787654',
    message_id: 'https://wa.me/+919872461320',
    sent_date: '22/6/2023,15:03',
    status: 'Read',
  },
  {
    mobile_number: '+91-8978787654',
    message_id: 'https://wa.me/+919872461320',
    sent_date: '22/6/2023,15:03',
    status: 'Failed',
  },
];
// END Campaign Individual View Data

// Business Settings Data
export const allBusinessSettings: AllBusinessSettings = {
  name: 'Cholamandalam Finance',
  profileUrl: 'assets/img/customer/businessSettings/businessProfileImg.svg',
  email: 'finance@cholamandalam.com',
  industry: 'Finance',
  desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry's. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s",
  address:
    ' Cholamandalam Investment and Finance Company Limited (Chola),Dare House, 1st Floor,No.2, N.S.C.Bose Road, Parrys, Chennai - 600001',
  callBackUrl: 'www.cholamandalam.com',
  website: 'www.cholamandalam.com',
  whatsappNumber: +919000090000,
  fbMID: '11940563454534534',
  associateName: 'Cholamandalam Investment & Finance Company Limited',
  sourceID: '0e5ca03ac23da78614e4e6ad917712f3',
  apiKey: 'c23da78614e4e6a0e5ca03ac23d',
  deliveryUrl:
    'nb5.botjet.ai/whatsappbync/generic/8831b44c-f3fe-74ed-2ca3-486b0ed869b8',
  defaultMsg:
    " Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has beenthe industry's standard dummy text ever since the 1500s",
};
// END Business Settings Data

//Summary reports inital-data
export const initalSummaryReportsData: InitalSummaryReportsData = {
  marketing: {
    submitted: 0,
    sent: 0,
    optout: 0,
    delivered: 0,
    read: 0,
    failed: 0,
    pending: 0,
    unread: 0,
  },
  utility: {
    submitted: 0,
    sent: 0,
    optout: 0,
    delivered: 0,
    read: 0,
    failed: 0,
    pending: 0,
    unread: 0,
  },
  authentication: {
    submitted: 0,
    sent: 0,
    optout: 0,
    delivered: 0,
    read: 0,
    failed: 0,
    pending: 0,
    unread: 0,
  },
  total: {
    submitted: 0,
    sent: 0,
    optout: 0,
    delivered: 0,
    read: 0,
    failed: 0,
    pending: 0,
    unread: 0,
  },
  // file_name: null,
  // file_url: null,
};
