import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, takeUntil, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { CustomValidators } from 'src/app/services/CustomValidators';
import { ActivatedRoute, Router, Params } from '@angular/router';
import {
  selectProfilesDtlsData,
  selectSsoProfilesDtlsData,
} from 'src/app/store/common.selectors';
import { SharedService } from 'src/app/services/shared.service';
import {
  ReportTemplateVariables,
  ReportsDatum,
} from 'src/app/services/reports.service';
import * as campaignactions from 'src/app/customer/campaign-manager/store/campaign.actions';
import { CampaignVariables } from 'src/app/services/campaign.service';
import {
  AuthUserData,
  ChannelCredentials,
  RbacPolicies,
} from 'src/app/appdata/auth.model';
import { CampaignDetails } from 'src/app/appdata/campaign.model';
import { selectCampaignActionData } from '../../campaign-manager/store/campaign.selectors';
import { AlertService } from 'src/app/alert/alert.service';
import { SelectionModel } from '@angular/cdk/collections';
import { skeleton_data } from '../../whatsapp-preview/whatsapp-preview.data';
import { selectReportDetails } from '../store/reports.selectors';
import * as reportactions from 'src/app/customer/reports/store/reports.actions';
import { environment } from 'src/environments/environment';

export interface Campaign {
  name: string;
}

@Component({
  selector: 'app-report-create',
  templateUrl: './report-create.component.html',
  styleUrls: ['./report-create.component.scss'],
})
export class ReportCreateComponent implements OnInit, OnDestroy {
  createReportForm: FormGroup;
  saveReportTempForm: FormGroup;
  destroy$: Subject<boolean> = new Subject<boolean>();
  showWrapper: boolean = false;
  channelSub: Subscription;
  selection = new SelectionModel<CampaignDetails>(true, []);
  parameters = new SelectionModel<string>(true, []);
  reportTypeList = [{ name: 'Campaign', value: 'campaign' }];
  tempCategories = [
    { name: 'Marketing', value: 'marketing' },
    { name: 'Utility', value: 'utility' },
    { name: 'Authentication', value: 'authentication' },
  ];
  showCampaignList: boolean = false;
  showTable = false;
  showAllCampaigns = false;
  showAllParameters = false;
  campaignsList: CampaignDetails[] = [];
  totalCampaigns: number = 0;
  displayedColumns: string[] = [
    'report_name',
    'creation_date',
    'date_range',
    'report_type',
    'category',
    'report_properties',
    'campaign_ids',
    'created_by',
    'status',
  ];
  templateparameterslist: string[] = [
    'waba_number',
    'campaign_id',
    'template_id',
    'template_name',
    'campaign_name',
    'msisdn',
    'status',
    'message_id',
    'submitted_at',
    'failed_at',
    'sent_at',
    'delivered_at',
    'read_at',
    'error',
    'error_code',
  ];
  page = 1;
  pageSize = 20;
  reportCharCount = 0;
  rTempCharCount = 0;
  maxCharCount = 16;
  channel_credentails: ChannelCredentials;
  campaign_payload_var: CampaignVariables = {
    accountid: null,
    channel: null,
    bspid: null,
    wabano: null,
    campaign_name: null,
    page: this.page,
    size: this.pageSize,
    template_category: null,
    type: null,
    tag: null,
    profile: null,
    status: 'completed',
    startdate: null,
    enddate: null,
  };
  reportVariableData: ReportTemplateVariables = {
    account_id: null,
    channel: null,
    wabano: null,
    template_name: null,
    report_name: null,
    category: null,
    startdate: null,
    enddate: null,
    page: this.page,
    size: this.pageSize,
  };
  reportsList: ReportsDatum[] = [];
  exportTemplate: any;
  userDetails: AuthUserData | any;
  permissions: RbacPolicies;
  reportDateSubscription: Subscription;
  skeletonData = skeleton_data;
  campaigns: Campaign[] = [
    { name: 'Lemon' },
    { name: 'Lime' },
    { name: 'Apple' },
  ];

  constructor(
    private fb: FormBuilder,
    private cv: CustomValidators,
    private modalService: NgbModal,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private alertMsg: AlertService,
    private shareservice: SharedService,
    private readonly store: Store
  ) {}

  // , Validators.pattern(this.cv.nameRegex)
  ngOnInit(): void {
    this.subscribeToChannelSub();
    this.createReportForm = this.fb.group({
      reportName: [
        null,
        [Validators.required, Validators.pattern(this.cv.nameRegex)],
      ],
      reportType: ['campaign', Validators.required],
      tempCategory: [null, Validators.required],
      campaignlist: [null, Validators.required],
      reportParameters: [null, Validators.required],
    });

    this.saveReportTempForm = this.fb.group({
      reportTempName: [
        null,
        [Validators.required, Validators.pattern(this.cv.nameRegex)],
      ],
    });

    this.createReportForm.get('reportName').valueChanges.subscribe((t) => {
      this.reportCharCount = t ? t.length : 0;
    });

    this.saveReportTempForm
      .get('reportTempName')
      .valueChanges.subscribe((t) => {
        this.rTempCharCount = t ? t.length : 0;
      });

    this.subscribeToRouting();
    this.subscribeToProfileData();
    this.shareservice.datePickerObject.next(null);
    this.subscribeToDatePicker();
    this.subscribeToCampaignActions();
    this.subscribeFormChanges();
    this.subscribeToReportData();
  }

  subscribeToRouting() {
    if (this.router.url.includes('export')) {
      this.activatedRoute.params.subscribe((params: Params) => {
        const template_name = params['name'];
        this.reportVariableData.template_name = template_name;
        this.reportVariableData.account_id =
          this.channel_credentails.account_id;
        this.reportVariableData.channel =
          this.channel_credentails.channel_name.toLowerCase();
        this.createReportForm.controls['reportType'].clearValidators();
        this.createReportForm.controls['tempCategory'].clearValidators();
        this.createReportForm.controls['reportType'].updateValueAndValidity();
        this.createReportForm.controls['tempCategory'].updateValueAndValidity();
        this.showCampaignList = true;
        this.store.dispatch(
          reportactions.reporttemplates.reportTemplatesList({
            payload: this.reportVariableData,
          })
        );
      });
    }
  }

  subscribeToReportData() {
    this.store
      .select(selectReportDetails)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: any) => {
        if (res.createreport) {
          if (res.createreport.status_code === 200) {
            this.alertMsg.alertSuccess('Success', res.createreport.message);
            this.gotoListing();
          }
        }
        if (res.createreporttemplate) {
          if (res.createreporttemplate.status_code === 200) {
            this.modalService.dismissAll();
            this.alertMsg.alertSuccess(
              'Success!',
              res.createreporttemplate.message
            );
          }
        } else if (res.reporttemplateslist) {
          if (res.reporttemplateslist.status_code === 200) {
            this.exportTemplate =
              res.reporttemplateslist.data.templates_data[0];
            this.createReportForm
              .get('tempCategory')
              .setValue(this.exportTemplate.category);
            this.templateparameterslist = JSON.parse(
              this.exportTemplate.properties
            );
            this.toggleParametersRows();
            this.addParametersListToForm();
          } else {
            this.exportTemplate = null;
          }
        } else if (res.error) {
          if (res.error.status_code === 400) {
            this.alertMsg.alertWarning('Warning', res.error.message[0]);
          }
        }
      });
  }

  subscribeToProfileData() {
    if (environment.sso) {
      this.store
        .select(selectSsoProfilesDtlsData)
        .pipe(takeUntil(this.destroy$))
        .subscribe((res) => {
          if (res.loggedInUserDetails) {
            this.userDetails = res.loggedInUserDetails;
            this.permissions = res.loggedInUserDetails.rbac.rbac_policies;
          }
        });
    } else {
      this.store
        .select(selectProfilesDtlsData)
        .pipe(takeUntil(this.destroy$))
        .subscribe((res: any) => {
          if (res.loggedInUserDetails) {
            this.userDetails = res.loggedInUserDetails;
            this.permissions = this.userDetails.profile.acl.rbac.rbac_policies;
          }
        });
    }
  }

  subscribeFormChanges() {
    this.createReportForm.get('tempCategory').valueChanges.subscribe((t) => {
      if (t) {
        this.campaign_payload_var = JSON.parse(
          JSON.stringify(this.campaign_payload_var)
        );
        this.campaign_payload_var.template_category = t;
        this.store.dispatch(
          campaignactions.fetchcampaignslist({
            payload: this.campaign_payload_var,
          })
        );

        this.campaignsList = [];
        this.totalCampaigns = 0;
        this.selection.clear();
        this.createReportForm.get('campaignlist').setValue(null);
      }
    });
  }

  subscribeToChannelSub() {
    this.channelSub = this.shareservice.selectedBA$.subscribe((value: any) => {
      if (value) {
        this.channel_credentails = value.channel_credentials;
        this.campaign_payload_var = JSON.parse(
          JSON.stringify(this.campaign_payload_var)
        );

        const { account_id, bsp_id, waba_number, channel_name } =
          value.channel_credentials;
        this.campaign_payload_var.accountid = account_id;
        this.campaign_payload_var.bspid = bsp_id;
        this.campaign_payload_var.wabano = waba_number;
        this.campaign_payload_var.channel = channel_name.toLowerCase();
      }
    });
  }

  subscribeToDatePicker() {
    this.reportDateSubscription = this.shareservice.datePickerObject.subscribe(
      (value) => {
        if (value) {
          if (value.from && value.to) {
            const fromDate: NgbDateStruct = value.from;
            const toDate: NgbDateStruct = value.to;
            this.campaign_payload_var = JSON.parse(
              JSON.stringify(this.campaign_payload_var)
            );
            this.campaign_payload_var.startdate = `${fromDate.year}-${fromDate.month}-${fromDate.day}`;
            this.campaign_payload_var.enddate = `${toDate.year}-${toDate.month}-${toDate.day}`;
            this.store.dispatch(
              campaignactions.fetchcampaignslist({
                payload: this.campaign_payload_var,
              })
            );
          }
          if (value.action === 'Date Cleared') {
            this.campaign_payload_var = JSON.parse(
              JSON.stringify(this.campaign_payload_var)
            );
            this.campaign_payload_var.startdate = null;
            this.campaign_payload_var.enddate = null;
            this.campaignsList = [];
            this.totalCampaigns = 0;
            this.selection.clear();
            this.createReportForm.get('tempCategory').setValue(null);
          }
        }
      }
    );
  }

  subscribeToCampaignActions() {
    this.store
      .select(selectCampaignActionData)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: any) => {
        if (res.campaignList && res.campaignList.status_code === 200) {
          this.campaignsList = res.campaignList.data;
          this.totalCampaigns = res.campaignList.count;
        }

        if (res.campaign_error) {
          const dispErr = res.campaign_error.error.message;
          this.alertMsg.alertDanger('Error', dispErr);
        } else if (res.error) {
          if (res.error && res.error.status_code === 404) {
            this.campaignsList = [];
            this.totalCampaigns = 0;
            const dispErr = res.error.message;
            this.alertMsg.alertDanger('Error', dispErr);
          }
        }
      });
  }

  get crFC() {
    return this.createReportForm.controls;
  }
  get rtNameFC() {
    return this.saveReportTempForm.controls;
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.totalCampaigns;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.campaignsList);

    const selected_data: string[] = [];
    this.selection.selected.forEach((t) => selected_data.push(t.id));
    const values = selected_data.length > 0 ? selected_data : null;
    this.createReportForm.get('campaignlist').setValue(values);
  }

  addCampaignListToForm() {
    const selected_data: string[] = [];
    this.selection.selected.forEach((t) => selected_data.push(t.id));
    const values = selected_data.length > 0 ? selected_data : null;
    this.createReportForm.get('campaignlist').setValue(values);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: CampaignDetails): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${
      row.id + 1
    }`;
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isParametersSelected() {
    const numSelected = this.parameters.selected.length;
    const numRows = this.templateparameterslist.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleParametersRows() {
    if (this.isParametersSelected()) {
      this.parameters.clear();
      this.showWrapper = true;
      return;
    }

    this.parameters.select(...this.templateparameterslist);

    const selected_data: string[] = [];
    this.parameters.selected.forEach((t) => selected_data.push(t));
    const values = selected_data.length > 0 ? selected_data : null;
    this.showWrapper = values ? true : false;
    this.createReportForm.get('reportParameters').setValue(values);
  }

  addParametersListToForm() {
    const selected_data: string[] = [];
    this.parameters.selected.forEach((t) => selected_data.push(t));

    const values = selected_data.length > 0 ? selected_data : null;
    this.showWrapper = values ? true : false;
    this.createReportForm.get('reportParameters').setValue(values);
  }

  /** The label for the checkbox on the passed row */
  checkboxParametersLabel(row?: string): string {
    if (!row) {
      return `${this.isParametersSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.parameters.isSelected(row) ? 'deselect' : 'select'} row ${
      row + 1
    }`;
  }

  onCreateReport(form: FormGroup) {
    if (form.invalid) {
      return;
    }

    this.createReportForm = this.fb.group({
      reportName: [
        null,
        [Validators.required, Validators.pattern(this.cv.nameRegex)],
      ],
      reportType: ['campaign', Validators.required],
      tempCategory: [null, Validators.required],
      campaignlist: [null, Validators.required],
      reportParameters: [null, Validators.required],
    });

    const create_payload = {
      account_id: this.campaign_payload_var.accountid,
      bsp_id: this.campaign_payload_var.bspid,
      channel: this.campaign_payload_var.channel,
      waba_number: this.campaign_payload_var.wabano,
      created_by:
        this.userDetails.profile.first_name +
        ' ' +
        this.userDetails.profile.last_name,
      report_name: form.value.reportName,
      category: form.value.tempCategory,
      report_type: form.value.reportType,
      properties: form.value.reportParameters,
      campaign_ids: form.value.campaignlist,
    };

    this.store.dispatch(
      reportactions.reportdetails.createReport({ payload: create_payload })
    );
  }

  openModal(content: TemplateRef<string>, data: string) {
    const sizes: any = {
      viewTemplate: 'lg',
      addSample: 'xl',
      deleteTemplate: 'md',
    };

    this.modalService.open(content, {
      centered: true,
      scrollable: true,
      backdrop: 'static',
      size: sizes[data] || 'md',
    });
  }

  gotoListing() {
    this.router.navigate(['customer', this.userDetails.profile.id, 'reports'], {
      state: {
        Navigation: 1,
      },
    });
    this.createReportForm.reset();
    this.saveReportTempForm.reset();
  }

  saveAsTemplate() {
    if (this.saveReportTempForm.invalid || this.createReportForm.invalid) {
      return;
    }

    const template_payload = {
      account_id: this.campaign_payload_var.accountid,
      bsp_id: this.campaign_payload_var.bspid,
      channel: this.campaign_payload_var.channel,
      waba_number: this.campaign_payload_var.wabano,
      created_by:
        this.userDetails.profile.first_name +
        ' ' +
        this.userDetails.profile.last_name,
      template_name: this.saveReportTempForm.value.reportTempName,
      category: this.createReportForm.value.tempCategory,
      report_type: 'custom',
      properties: this.createReportForm.value.reportParameters,
    };

    this.store.dispatch(
      reportactions.reporttemplates.createReportTemplate({
        payload: template_payload,
      })
    );
  }

  onCategoryChange(event: any) {
    this.showCampaignList = event;
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    this.shareservice.datePickerObject.next(null);
    this.reportDateSubscription.unsubscribe();
    this.campaignsList = [];
    this.totalCampaigns = 0;
  }
}
