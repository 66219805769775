import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, of } from 'rxjs';
import { catchError, exhaustMap, map, mergeMap, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AlertService } from '../alert/alert.service';
import { AnalyticsData } from '../appdata/analytics.model';
import { SsoLoginData, Ssoprofile } from '../appdata/auth.model';
import { MediaServerList } from '../appdata/fileserver.model';
import { UploadFileResponse } from '../appdata/fileupload.model';
import { WhitelabelingData } from '../appdata/initialwlb.data';
import { SharedService } from '../services/shared.service';
import { SsoService } from '../services/sso.service';
import { WhiteLabelService } from '../services/whitelabel.service';
import * as commonActions from './common.actions';

@Injectable()
export class CommonEffects {
  whiteLabeledData: any = WhitelabelingData;
  clientId: string;
  profileData: Ssoprofile;
  constructor(
    private actions$: Actions,
    private shareservice: SharedService,
    private alertMsg: AlertService,
    private ssoservice: SsoService,
    private whtlbservice: WhiteLabelService,
    private router: Router
  ) {}

  initiateFileUpload$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(commonActions.initiateuploadFile),
      exhaustMap((action) =>
        this.shareservice
          .fileUpload(
            action.file,
            action.accountid,
            action.mobileno,
            action.channel,
            action.createdby,
            action.is_active
          )
          .pipe(
            map((response: UploadFileResponse) => {
              return commonActions.initiateuploadFileSuccess({
                data: response,
              });
            }),
            catchError((error: any) =>
              of(commonActions.initiateuploadFileFailure(error))
            )
          )
      )
    );
  });

  initiateUrlUpload$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(commonActions.initiateuploadUrl),
      exhaustMap((action) =>
        this.shareservice
          .fileUrlUpload(
            action.url,
            action.accountid,
            action.mobileno,
            action.channel,
            action.createdby
          )
          .pipe(
            map((response: UploadFileResponse) => {
              return commonActions.initiateuploadUrlSuccess({
                data: response,
              });
            }),
            catchError((error: any) =>
              of(commonActions.initiateuploadUrlFailure(error))
            )
          )
      )
    );
  });

  fetchFileServerData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(commonActions.fetchMediaServerData),
      exhaustMap((action) =>
        this.shareservice.fetchmediaServer(action.payload).pipe(
          map((response: MediaServerList) => {
            return commonActions.fetchMediaServerDataSuccess({
              data: response,
            });
          }),
          catchError((error: any) =>
            of(commonActions.fetchMediaServerDataFailure(error))
          )
        )
      )
    );
  });

  deleteFileServerData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(commonActions.deleteMediaServerData),
      exhaustMap((action) =>
        this.shareservice.deleteMediaServerData(action.payload).pipe(
          map((response: any) => {
            return commonActions.deleteMediaServerDataSuccess({
              data: response,
            });
          }),
          catchError((error: any) =>
            of(commonActions.deleteMediaServerDataFailure(error))
          )
        )
      )
    );
  });

  fetchAnalyticsData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(commonActions.fetchanalyticsdata),
      exhaustMap((action) =>
        this.shareservice
          .getAnalyticsData(
            action.accountid,
            action.bspid,
            action.startdate,
            action.enddate,
            action.page,
            action.size,
            action.campaign_id
          )
          .pipe(
            map((response: AnalyticsData) => {
              return commonActions.fetchanalyticsdataSuccess({
                data: response,
              });
            }),
            catchError((error: any) =>
              of(commonActions.fetchanalyticsdataFailure(error))
            )
          )
      )
    );
  });

  getWblData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(commonActions.getWhiteLabelData),
      exhaustMap((action) =>
        this.shareservice.getWlbdatabyaccountid(action.accountid).pipe(
          map((response: any) => {
            if (response.status_code === 200 || response.status_code === 201) {
              return commonActions.getWhiteLabelDataSuccess({
                data: response.data,
              });
            }
          }),
          catchError((error: any) =>
            of(commonActions.getWhiteLabelDataFailure(error))
          )
        )
      )
    );
  });

  fetchWblData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(commonActions.fetchWhiteLabelData),
      exhaustMap(() =>
        this.shareservice.fetchWlbData().pipe(
          map((response: any) => {
            return commonActions.fetchWhiteLabelDataSuccess({
              data: response,
            });
          }),
          catchError((error: any) =>
            of(commonActions.fetchWhiteLabelDataFailure(error))
          )
        )
      )
    );
  });

  wblCreateData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(commonActions.createWhiteLabelData),
      exhaustMap((action) =>
        this.shareservice.createWlbData(action.payload, action.accountid).pipe(
          map((response: any) => {
            if (response.status_code === 200 || response.status_code === 201) {
              return commonActions.createWhiteLabelDataSuccess({
                data: response.data,
              });
            }
          }),
          catchError((error: any) =>
            of(commonActions.createWhiteLabelDataFailure(error))
          )
        )
      )
    );
  });

  wblUpdateData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(commonActions.updateWhiteLabelData),
      exhaustMap((action) =>
        this.shareservice.updateWlbData(action.payload, action.accountid).pipe(
          map((response: any) => {
            if (response.status_code === 200 || response.status_code === 201) {
              this.alertMsg.alertSuccess('Success', response.message);
              return commonActions.updateWhiteLabelDataSuccess({
                data: response.data,
              });
            }
          }),
          catchError((error: any) =>
            of(commonActions.updateWhiteLabelDataFailure(error))
          )
        )
      )
    );
  });

  wblFileUploadData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(commonActions.uploadFileData),
      exhaustMap((action) =>
        this.shareservice.wlbUpload(action.file, action.accountid).pipe(
          map((response: any) => {
            return commonActions.uploadFileDataSuccess({
              data: response,
            });
          }),
          catchError((error: any) =>
            of(commonActions.uploadFileDataFailure(error))
          )
        )
      )
    );
  });

  changedefault$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(commonActions.changeDefault),
      exhaustMap((action) =>
        this.shareservice
          .changeProductDefault(action.profileid, action.payload)
          .pipe(
            map((response: any) => {
              return commonActions.changeDefaultSuccess({
                data: response,
              });
            }),
            catchError((error: any) =>
              of(commonActions.changeDefaultFailure(error))
            )
          )
      )
    );
  });

  getLicenseData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(commonActions.getLicenseDetails),
      exhaustMap((action) =>
        this.shareservice.getLicenseDetails(action.accountid).pipe(
          map((response: any) => {
            return commonActions.getLicenseDetailsSuccess({
              data: response,
            });
          }),
          catchError((error: any) =>
            of(commonActions.getLicenseDetailsFailure(error))
          )
        )
      )
    );
  });

  //sso effects
  setToken$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(commonActions.SsoActions.startSsoActions),
      map((action) => {
        this.clientId = action.clientId;
        return commonActions.SsoActions.setSsoToken({ data: action.jwtToken });
      })
    );
  });

  //create profile data object
  getprofile(profile: any) {
    const profileData: Ssoprofile = { id: null };
    if (profile?.sub) {
      profileData.id = profile.sub;
      profileData.first_name = profile.given_name;
      profileData.last_name = profile.family_name;
      profileData.email = profile.email;
    }
    return profileData;
  }

  //set progile data to store
  setProfileData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(commonActions.SsoActions.startSsoActions),
      map((action) => {
        const profile = this.ssoservice.decodeToken(action.jwtToken);
        const profileData = this.getprofile(profile);
        this.profileData = profileData;
        this.clientId = action.clientId;
        return commonActions.SsoActions.setProfileData({
          data: profileData,
          clientId: action.clientId,
        });
      })
    );
  });

  //call login action
  loginUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(commonActions.SsoActions.startSsoActions),
      map((action) => {
        return commonActions.SsoActions.loginUser({
          payload: { clientId: action.clientId },
          loader: true,
        });
      })
    );
  });

  authenticateUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(commonActions.SsoActions.loginUser),
      exhaustMap((action) =>
        this.ssoservice.ssoLogin(action.payload).pipe(
          map((response: SsoLoginData) => {
            if (response.status_code === 200) {
              if (response.data) {
                response.data.profile = this.profileData;
                if (response.data.white_label?.wlb_settings) {
                  this.whtlbservice.applyWhiteLabeling(
                    response.data.white_label.wlb_settings
                  );
                } else {
                  this.whtlbservice.applyWhiteLabeling(this.whiteLabeledData);
                }
              }
              return commonActions.SsoActions.loginUserSuccess({
                data: response.data,
              });
            }
          }),
          catchError((error: any) =>
            of(commonActions.SsoActions.loginUseFailure({ error: error }))
          )
        )
      )
    );
  });

  setWlbData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(commonActions.SsoActions.loginUserSuccess),
      map((action) => {
        return commonActions.setWhiteLabelData({
          data: action.data.white_label
            ? action.data.white_label.wlb_settings
            : this.whiteLabeledData,
        });
      })
    );
  });

  //login fliure and auto logout
  loginUseFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          commonActions.SsoActions.loginUseFailure,
          commonActions.SsoActions.autoLogoutUser
        ),
        tap((action) => {
          if (
            action.error &&
            (action.error?.redirectionUrl ||
              action.error?.error?.redirectionUrl)
          ) {
            const msg = action.error.error
              ? action.error.error.message
              : action.error.message;
            this.alertMsg.alertDanger('Error', msg);
            if (this.clientId != 'ocmp-test') {
              const status_code = action.error.status_code
                ? action.error.status_code
                : action.error.error.status_code;
              if (status_code != 404) {
                setTimeout(() => {
                  this.ssoservice.clearSesssion();
                  const url = action.error.redirectionUrl
                    ? action.error.redirectionUrl
                    : action.error.error.redirectionUrl;
                  if (
                    this.router.url.split('?')[0].includes('page-not-found')
                  ) {
                    window.location.href = url;
                  } else {
                    window.location.href =
                      url +
                      '?source_url=' +
                      window.btoa(
                        environment.frontendurl +
                          '/' +
                          window.btoa(this.clientId) +
                          this.router.url.split('?')[0]
                      );
                  }
                }, 2000);
              } else if (status_code == 404) {
                this.router.navigate(['support-admin']);
              }
            } else {
              this.ssoservice.clearSesssion();
              window.location.href = action.error.redirectionUrl
                ? action.error.redirectionUrl
                : action.error.error.redirectionUrl;
            }
          } else {
            this.router.navigate(['page-not-found']);
          }
        })
      );
    },
    {
      dispatch: false,
    }
  );

  // validateTokenSuccess$ = createEffect(
  //   () => {
  //     return this.actions$.pipe(
  //       ofType(commonActions.SsoActions.validateSsoTokenSuccess),
  //       map(() => {
  //         return EMPTY;
  //       })
  //     );
  //   },
  //   {
  //     dispatch: false,
  //   }
  // );

  validateToken$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(commonActions.SsoActions.validateSsoToken),
      exhaustMap((action) =>
        this.ssoservice.ssoTokenValidation(action.payload).pipe(
          mergeMap((response: any) => {
            if (response && response.status_code === 200) {
              return EMPTY;
            }
          }),
          catchError((error: any) =>
            of(
              commonActions.SsoActions.validateSsoTokenFailure({
                error: error,
              })
            )
          )
        )
      )
    );
  });

  validateTokenFailure$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(commonActions.SsoActions.validateSsoTokenFailure),
      map((action) => {
        return commonActions.SsoActions.autoLogoutUser(action.error);
      })
    );
  });

  logOutUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(commonActions.SsoActions.logoutUser),
      exhaustMap((action) =>
        this.ssoservice.ssoLogut(action.data).pipe(
          map((response: any) => {
            if (response && response.status_code === 200) {
              this.ssoservice.clearSesssion();
              if (response.redirectionUrl) {
                window.location.href = response.redirectionUrl;
              }
            } else if (response.status_code === 500) {
              return commonActions.SsoActions.autoLogoutUser({
                error: response,
              });
            }
          }),
          catchError((error: any) =>
            of(commonActions.SsoActions.autoLogoutUser({ error: error }))
          )
        )
      )
    );
  });
}
